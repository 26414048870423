const baseUrl = `${process.env.REACT_APP_API_URL}/emailService`;

const postEmailInstance = async (concertId, ticketId, email) => {
    return fetch(`${baseUrl}/email/${concertId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ticketId,
            email
        })
    }).then((res) => {
        if (res.ok) return res.json()
        return res.json().then(json => Promise.reject(json));
    }).then((res) => {
        return Promise.resolve(res);
    }).catch(e => {
        console.error(e.error)
    })
}

export const EmailService = {
    postEmailInstance
}