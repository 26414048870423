import { collection, getDocs, getDoc, query, doc, orderBy } from 'firebase/firestore'
import { db } from '../firebase-config';

const baseUrl = `${process.env.REACT_APP_API_URL}/concertService`;

const getConcerts = async () => {
    let concertsRef = collection(db, "concerts");
    const q = query(concertsRef, orderBy("date"));
    const data = await getDocs(q);
    const concerts = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    return concerts;
}

const getConcertById = async (id) => {
    const concertDoc = doc(db, "concerts", id);
    const datas = await getDoc(concertDoc);

    const sold = await getSold(id);
    const data = datas.data()

    const stock = data.maxQuantity - sold;

    const concert = { ...data, id: datas.id, stock };
    return concert;
}

const getTickets = async (concertId) => {
    const ticketsRef = collection(db, "concerts", concertId, "tickets");
    const data = await getDocs(ticketsRef);

    const tickets = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    return tickets;
}

const getTicketById = async (concertId, ticketId) => {
    const ticketsRef = doc(db, "concerts", concertId, "tickets", ticketId);
    const data = await getDoc(ticketsRef);

    const ticket = { ...data.data(), id: data.id };
    return ticket;
}

const redeemTicket = async (concertId, ticketId, customerId) => {
    return fetch(`${baseUrl}/concerts/${concertId}/tickets/${ticketId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ [`customers.${customerId}.redeemed`]: true })
    }).then((res) => {
        if (res.ok) return res.json()
        return res.json().then(json => Promise.reject(json));
    }).then((res) => {
        return Promise.resolve(res);
    }).catch(e => {
        console.error(e.error)
    })
}

const putEmailById = async (concertId, ticketId, email) => {
    return fetch(`${baseUrl}/concerts/${concertId}/tickets/${ticketId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
    }).then((res) => {
        if (res.ok) return res.json()
        return res.json().then(json => Promise.reject(json));
    }).then((res) => {
        return Promise.resolve(res);
    }).catch(e => {
        console.error(e.error)
    })
}

const getPromocode = async (concertId) => {
    const promoRef = collection(db, "concerts", concertId, "promocode");
    const data = await getDocs(promoRef);

    const promocode = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    return promocode;
}

const postTicket = async (concertId, payload) => {
    return fetch(`${baseUrl}/concerts/${concertId}/tickets`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    }).then((res) => {
        if (res.ok) return res.json()
        return res.json().then(json => Promise.reject(json));
    }).then((res) => {
        return Promise.resolve(res);
    }).catch(e => {
        console.error(e.error)
    })
}

const getSold = async (concertId) => {
    const tickets = await getTickets(concertId);
    let sold = 0;
    tickets.forEach((ticket) => sold += parseInt(ticket.quantity));
    return sold;
}

const getInventory = async (concertId) => {
    return fetch(`${baseUrl}/concerts/${concertId}/inventory`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((res) => {
        if (res.ok) return res.json()
        return res.json().then(json => Promise.reject(json));
    }).then((res) => {
        return Promise.resolve(res);
    }).catch(e => {
        console.error(e.error)
    })
}

const putPromocode = async (concertId, promocode) => {
    return fetch(`${baseUrl}/concerts/${concertId}/promocode/${promocode}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((res) => {
        if (res.ok) return res.json()
        return res.json().then(json => Promise.reject(json));
    }).then((res) => {
        return Promise.resolve(res);
    }).catch(e => {
        console.error(e.error)
    })
}

export const ConcertsService = {
    getConcerts,
    getConcertById,
    getTickets,
    getTicketById,
    postTicket,
    putEmailById,
    redeemTicket,
    getPromocode,
    getInventory,
    getSold,
    putPromocode
}