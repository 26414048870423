import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 85vh;

    background-color: ${({ theme }) => theme.color.secondary};
`

export const MainInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    padding: 10px;
    
    height: inherit;
    width: inherit;

    @media(max-width: ${({ theme }) => theme.screen.sizeL}) {
        /* flex-direction: column-reverse; */
        flex-direction: column;
        /* overflow-y: scroll; */
        justify-content: flex-start;
    }
`

export const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex: 3;
    height: 100%;

    padding: 20px;
    margin-right: 20px;

    @media(max-width: ${({ theme }) => theme.screen.sizeL}) {
        margin-right: 0px;
        height: 50%;
        padding: 0px;
    }
`

export const Photo = styled.div`
    border: 1px solid ${({ theme }) => theme.color.border};
    background-color: white;

    max-width: 60%;
    max-height: 70%;

    & img {
        max-width: 100%;
        max-height: 100%;
    }

    @media(max-width: ${({ theme }) => theme.screen.sizeL}) {
        max-width: 80%;
        max-height: 90%;
    }
`

export const ActionItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 30px;
    width: 100%;
`

export const Button = styled.button`
    width: 180px;
    height: 40px;
    font-size: 18px;

    background-color: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.onPrimary};

    border: none;
    
    cursor: pointer;

    &:hover:enabled {
        transform: scale(0.98);
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
`

export const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({ center }) => center ? 'center' : 'flex-start'};
    justify-content: center;

    flex: 3;
    padding: 20px;

    @media(max-width: ${({ theme }) => theme.screen.sizeL}) {
        align-items: center;
    }
`

export const TitleContainer = styled.div`
    @media(max-width: ${({ theme }) => theme.screen.sizeL}) {
        text-align: center;
    }

    @media(max-width: ${({ theme }) => theme.screen.sizeS}) {
        font-size: 0.8em;
    }
`

export const DetailContainer = styled.div`
`

export const DetailWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    margin: 20px 0px;
`

export const Icon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-right: 30px;
    font-size: 28px;
`

export const Detail = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    font-size: 21px;

    & span {
        font-size: 12px;
    }

    @media(max-width: ${({ theme }) => theme.screen.sizeS}) {
        font-size: 1em;
        & span {
            font-size: 0.3em;
        }
    }
`

export const DescriptionContainer = styled.div`
    
`

export const Description = styled.p`
    
`

export const CastContainer = styled.div`
    
`

export const Casts = styled.div`
    display: flex;
    flex-direction: row;
`

export const DetailsWrapper = styled.div`
    padding: 10vw;
    background-color: ${({ theme }) => theme.color.secondary};
    
    @media(max-width: ${({ theme }) => theme.screen.sizeL}) {
        padding: 0px;
    }
`

export const ButtonContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: relative;
`

export const SpinnerContainer = styled.div`
    position: absolute;
    right: 0;
`