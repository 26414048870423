import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100%;

    position: relative;
`

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 100%;
    padding: 50px 0px;

    overflow-y: scroll;
`

export const FormValues = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h4 {
        margin: 0px;
        padding: 0px;
    }
`

export const FormSubmit = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 30px;
`

export const InputContainer = styled.div`
`

export const CustomerInfos = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
`

export const Customer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    margin-bottom: 10px;
`

export const PromoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 10px;
`

export const AddPromoBtn = styled.button`
    background-color: transparent;
    border: none;
    
    font-size: 12px;
    color: ${({ theme }) => theme.color.link};
    
    &:hover {
        opacity: 0.7;
    }

    transition: display 0.5s ease 0s;
    display: ${({ isVisible }) => isVisible ? 'block' : 'none'};
`

export const ButtonContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: relative;
`

export const SpinnerContainer = styled.div`
    position: absolute;
    right: 0;
`