import { Outlet } from 'react-router-dom';
import {
    Container,
    Section,
    ImageCell,
    Logo,
    SponsorContainer,
    Sponsor,
    Footer
} from './LittleTree.styled'
import LittleTreeNavbar from './NavBar/LittleTreeNavBar';

const LittleTree = () => {
    return (
        <Container>
            <Section>
                <ImageCell>
                    <Logo>
                        <img src={process.env.PUBLIC_URL + `/images/littleTree/logo-transparent.png`} alt='' />
                    </Logo>
                </ImageCell>
            </Section>
            <LittleTreeNavbar />
            <Outlet />
            <Footer>
                <SponsorContainer>
                    <Sponsor width={'50%'}>
                        <img src={process.env.PUBLIC_URL + `/images/littleTree/sprout-icon.png`} alt='' />
                    </Sponsor>
                    <p>Partnership</p>
                    <p>|</p>
                    <Sponsor>
                        <img src={process.env.PUBLIC_URL + `/images/littleTree/ofd-logo.png`} alt='' />
                    </Sponsor>
                    <Sponsor>
                        <img src={process.env.PUBLIC_URL + `/images/littleTree/dail-logo.png`} alt='' />
                    </Sponsor>
                    <p>|</p>
                    <Sponsor width={'50%'}>
                        <img src={process.env.PUBLIC_URL + `/images/littleTree/sprout-icon.png`} alt='' />
                    </Sponsor>
                </SponsorContainer>
            </Footer>
        </Container>
    )
}

export default LittleTree
