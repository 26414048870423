import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    height: 85vh;

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
`

export const FormContainer = styled.div`
    box-shadow: -5px 0px 5px -5px ${({ theme }) => theme.color.border};

    height: 100%;
    flex: 3;

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        box-shadow: 0px -5px 5px -5px ${({ theme }) => theme.color.border};
        width: 100%;
        height: 60vh;
    }
`

export const ProductInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex: 2;

    background-color: ${({ theme }) => theme.color.secondary};
    height: 100%;

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        flex-direction: row;
        width: 100%;
        height: 20vh;
    }
`

export const Title = styled.div`
    margin: 0px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        margin: 0px;
        font-size: 1em;
    }
`

export const Image = styled.img`
    max-width: 50%;
    box-shadow: -5px 5px 10px -5px ${({ theme }) => theme.color.border};

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        max-height: 50%;
    }
`

export const Stock = styled.div`
    padding: 0px;
    margin: 0px;

    & span {
        font-weight: bold;
    }

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        font-size: 0.8em;
    }
`

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        align-items: flex-start;
        margin-left: 10px;
    }
`