import styled from 'styled-components';
import { Button } from '../../../component';
import { FaRegEnvelope } from 'react-icons/fa';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const EmailContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 5px;
`

export const EmailText = styled.p`
    font-size: 1.2em;
    
    margin: 0px;
    padding: 0px;
    opacity: 1;

    color: ${({ theme }) => theme.color.link};

    cursor: pointer;
`

export const MailIcon = styled(FaRegEnvelope)`
    color: ${({ theme }) => theme.color.link};
    margin: 0px;
    padding: 0px;
    margin-right: 5px;
`

export const EditEmailWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const InputContainer = styled.div`
`

export const ButtonContainer = styled.div`
    width: 50px;
`

export const StyledButton = styled(Button)`
    background-color: transparent;
    color: ${({ theme }) => theme.color.link};
    width: 100%;
    font-size: 14px;
`