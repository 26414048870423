// const baseUrl = 'https://us-central1-woorie-dae88.cloudfunctions.net/stripeServerApp'
const baseUrl = `${process.env.REACT_APP_API_URL}/stripeServerApp`;

const ConcertPayload = ({ concertId, email, items, promocode, customers }) => {
    return {
        concertId,
        email,
        items,
        promocode,
        customers
    }
}

const createPaymentLink = (payload) => {

    return fetch(`${baseUrl}/checkoutSession`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    }).then((res) => {
        if (res.ok) return res.json()
        return res.json().then(json => Promise.reject(json));
    }).then((url) => {
        return Promise.resolve(url);
    }).catch(e => {
        console.error(e.error)
    })
}

const expireCheckoutSession = (sessionId) => {

    return fetch(`${baseUrl}/checkoutSession/expire`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            sessionId
        })
    }).then((res) => {
        if (res.ok) return res.json()
        return res.json().then(json => Promise.reject(json));
    }).then((res) => {
        return Promise.resolve(res);
    }).catch(e => {
        console.error(e.error)
    })
}

const getCheckoutSession = (sessionId) => {
    return fetch(`${baseUrl}/checkoutSession/${sessionId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((res) => {
        if (res.ok) return res.json()
        return res.json().then(json => Promise.reject(json));
    }).then((res) => {
        return Promise.resolve(res);
    }).catch(e => {
        console.error(e.error)
    })
}

export const StripeService = {
    ConcertPayload,
    createPaymentLink,
    expireCheckoutSession,
    getCheckoutSession
}