import styled from 'styled-components';

export const ContentWrapper = styled.div`
    max-width: 800px;
    width: 100%;
`

export const SponsorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        flex-direction: column;
    }
`

export const SponsorWrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 10px;
`

export const SponsorBenefits = styled.ul`
    text-align: center;
    list-style-type: none; /* Remove bullets */
    padding: 0;
    margin: 0;

    & > li {
        margin: 10px 0px;
    }
`