import styled from 'styled-components';
import { Button } from '../../../../component';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 10px;
`

export const Title = styled.h5`
    margin: 10px 0px;
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & * {
        margin: 0px;
    }
`

export const ApplyButton = styled(Button)`
    background-color: white;
    color: black;
    width: 30%;
    font-size: 14px;
`

export const Message = styled.p`
    padding: 0px;

    margin: 0px;
    margin-top: 2px;

    font-size: 11px;

    color: ${({ state }) => state === 'fail' ? 'red' : 'green'};
`