import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';
import {
  FlexWropper,
  PageWrapper
} from './App.styled';

// pages
import { Navbar } from './component';
import About from './pages/About/About';
import Concerts from './pages/Concerts/Concerts';
import Home from './pages/Home/Home';
import LittleTree from './pages/LittleTree/LittleTree';
import Checkout from './pages/Checkout/Checkout';
import Confirmation from './pages/Confirmation/Confirmation';
import Admin from './pages/Admin/Admin';

import Footer from './component/Footer/Footer';
import LittleTreeHome from './pages/LittleTree/Home/LittleTreeHome';
import SupportUs from './pages/LittleTree/SupportUs/SupportUs';
import ScrollToTop from './utils/ScrollToTop';

function App() {
  const location = useLocation();

  return (
    < FlexWropper >
      <Navbar />
      <PageWrapper>
        <ScrollToTop />
        {/* <SwitchTransition>
              <CSSTransition
                // in={inProp}
                // in={true}
                timeout={1000}
                classNames={'transition'}
              > */}
        {/* <TransitionGroup>
          <CSSTransition key={location.key} classNames="transition" timeout={300}> */}
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          {/* <Route path="*" element={<ErrorPage />} /> */}
          <Route path="/littletree" element={<LittleTree />}>
            <Route index element={<Navigate to="home" />} />
            <Route path="home" element={<LittleTreeHome />} />
            <Route path="support" element={<SupportUs />} />
            <Route path="*" element={<div>404 Page Not Found</div>} />
          </Route>
          {/* <Route path="/about" element={<About />}>
                <Route path=":id" element={<About />} />
              </Route> */}
          {/* <Route path="/concerts" element={<Concerts />}>
                <Route path=":concertId" element={<Concerts />} />
              </Route>
              <Route path="/checkout" element={<Checkout />} /> */}
          {/* <Route path="/confirmation/:session_id" element={<Confirmation />} /> */}
          {/* <Route path="/confirmation" element={<Confirmation />} /> */}
          {/* <Route path="/admin" element={<Admin />} /> */}
          <Route path="/*" element={<div>404 Page Not Found</div>} />
        </Routes>
        {/* </CSSTransition> */}
        {/* </TransitionGroup> */}
      </PageWrapper>
      <Footer />
    </FlexWropper >
  );
}

export default App;
