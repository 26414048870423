import styled from 'styled-components';
import { BsTagFill } from 'react-icons/bs';
import { FaTimes } from 'react-icons/fa';

export const Container = styled.div`
    
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: rgba(26,26,26,.05);
    padding: 10px;
    border-radius: ${({ theme }) => theme.border.radius};

    font-size: 14px;
`

export const TagIcon = styled(BsTagFill)`
    color: rgba(0,0,0,.5);
`

export const Text = styled.div`
    margin: 0px 10px;
    color: rgba(0,0,0,.7);
`

export const CloseBtn = styled(FaTimes)`
    color: rgba(0,0,0,.5);
    cursor: pointer;

    &:hover {
        color: rgba(0,0,0,.8);
    }
`