import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
`

export const Section = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: ${({ direction }) => direction ? direction : 'row'};
    flex: ${({ flex }) => flex ? flex : 1};

    width: 100%;

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        flex-direction: column;

        & div {
            flex: 1;
        }
    }
`

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
    padding: 0px 30px;

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        flex-direction: column;
        & div {
            text-align: center;
        }
    }
`

export const Cell = styled.div`
    display: flex;
    /* justify-content: center; */
    /* align-items: ${({ align }) => align ? align : 'center'}; */
    flex-direction: ${({ direction }) => direction ? direction : 'column'};

    ${(props) => props.direction?.includes('row') ?
        `justify-content: ${props.align ? props.align : 'center'};
        align-items: center;` :
        `justify-content: center;
        align-items: ${props.align ? props.align : 'center'};`
    }


    flex: ${({ flex }) => flex ? flex : 1};

    background-color: ${({ bgColor }) => bgColor ? bgColor : '#ffffff'};
    color: ${({ fontColor }) => fontColor ? fontColor : '#695533'};
    
    padding: 50px 50px;
`

export const ImageCell = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: ${({ flex }) => flex ? flex : 1};
`

export const Image = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    & > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        position: relative;
        height: 200px;
    }
`

export const TextWrapper = styled.div`
    width: ${({ width }) => width ? width : '100%'};

    text-align: ${({ textAlign }) => textAlign ? textAlign : 'left'};
    ${({ fontFamily }) => fontFamily &&
        `font-family: ${fontFamily};`
    };
    
    & h1,p {
        color: ${({ fontColor }) => fontColor ? fontColor : '#695533'};
    }

    & p {
        line-height: 1.5em;
    }

    & h1 {
        margin-bottom: 1em;
    }

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        width: 100%;
    }
`

export const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    height: 100%;
    width: 100%;

    overflow: scroll;

    & > div {
        margin: 0px 10px;
    }

    @media(max-width: ${({ theme }) => theme.screen.sizeXL}) {
        flex-direction: column;
        & > div {
            margin: 10px 0px;
        }
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 20px;
`

export const SponsorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;

    & > p {
        color: #456e45;
        font-weight: bold;
        margin: 0px 5px;
    }
`

export const Sponsor = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 100%;
    max-height: 50px;
    max-width: 100px;
    margin: 10px;

    & > img {
        height: 100%;
        width: 100%;
        width: ${({ width }) => width ? width : '100%'};
        object-fit: contain;
        object-position: 50% 50%;
    }
`

export const Logo = styled.div`
    width: 100%;
    height: 100%;
    max-width: 300px;
    padding: 20px;

    & img {
        width: 100%;
        height: 100%;
    }

    /*@media(max-width: ${({ theme }) => theme.screen.sizeL}) {
        max-width: 80%;
        max-height: 90%;
    } */
`