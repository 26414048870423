import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    /* max-width: ${({ maxWidth }) => maxWidth ? maxWidth : '250px'}; */
    width: 100%;
    height: 100%;

    position:relative;
    
    ${({ img }) => img && `background-image: url("${img}");`};
    background-color: #cccccc; /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    border-radius: 40px;
`

export const BackgroundMask = styled.div`
    border-radius: 40px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .3; 
    background-color: #000000; /* Used if the image is unavailable */
    z-index: 0;
`

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    text-align: center;
    color: #ffffff;

    padding: 30px;
    z-index: 1;
`

export const Title = styled.div`
    font-size: 1.45em;
    margin-bottom: 10px;
    font-weight: bold;
`

export const Subtitle = styled.div`
    margin-bottom: 20px;
    font-weight: bold;
`

export const Description = styled.div`
    font-size: 0.8em;
`