import styled from 'styled-components';

export const Container = styled.div`
    margin: 10px 0px;
    width: ${({ width }) => width ? width : '250px'};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${({ align }) => align ? align : 'center'};
`

export const Wrapper = styled.div`
    /* margin: 10px 0px; */
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const ErrorMessage = styled.span`
    display: block;
    color: red;
    font-size: 0.5em;

    margin-top: 3px;
    padding: 0px;
`

export const StyledInput = styled.input`
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
    width: 100%;

    &:invalid {
       ${({ focus }) => focus && 'border: 1px solid red;'}
    }
`