import {
    Container
} from './Logo.styled';

const Logo = () => {
    return (
        <Container>
            <img src={process.env.PUBLIC_URL + `/logo_transparent.png`} alt='WOORIE' />
        </Container>
    )
}

export default Logo