import {
    Container,
    NavContainer,
    Nav,
    StyledNavLink
} from './LittleTreeNavBar.styled'

const LittleTreeNavbar = () => {

    return (
        <Container>
            <NavContainer>
                <Nav>
                    <StyledNavLink to="home">Little Tree</StyledNavLink>
                    <StyledNavLink to="support">Support Us</StyledNavLink>
                </Nav>
            </NavContainer>
        </Container>
    )
}

export default LittleTreeNavbar