import {
    Container,
    Wrapper,
    ImgFlex,
    Name,
    ContentFlex,
    ContentWrapper,
    QRWrapper,
    Details,
    DetailsWrapper,
    MusicIcon
} from './Ticket.styled'
import { QRCodeSVG } from 'qrcode.react';

const Ticket = ({
    concertId,
    qrcode,
    name,
    type,
    date,
    location,
    time,
    colors
}) => {
    return (
        <Container>
            <Wrapper>
                <ImgFlex borderColor={colors[type]}>
                    <Name>{name}</Name>
                    <img src={process.env.PUBLIC_URL + `/images/concerts/${concertId}/ticket/type${type + 1}.jpg`} alt='ticketImg' />
                </ImgFlex>
                <ContentFlex>
                    <ContentWrapper>
                        <QRWrapper>
                            <QRCodeSVG
                                id="qrcode"
                                value={qrcode}
                                size={'100%'}
                            />
                        </QRWrapper>
                        <DetailsWrapper>
                            <Details colors={colors}>
                                <h4>Location</h4>
                                <p>{location}</p>
                            </Details>
                            <Details colors={colors}>
                                <h4>Date</h4>
                                <p>{date}</p>
                            </Details>
                            <Details colors={colors}>
                                <h4>Time</h4>
                                <p>{time}</p>
                            </Details>
                        </DetailsWrapper>
                    </ContentWrapper>
                    <MusicIcon color={colors[(type + 1) % 2]} />
                </ContentFlex>
            </Wrapper>
        </Container>
    )
}

export default Ticket