import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
`

export const TIcketCount = styled.div`
    margin: 10px 0px;
    font-size: 20px;
    width: 100%;
    text-align: center;
`