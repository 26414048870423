import {
    StyledButton
} from './Button.styled'

const Button = (props) => {
    const { bgColor, color, borderColor, ...inputProps } = props;
    return (
        <StyledButton
            bgColor={bgColor}
            color={color}
            borderColor={borderColor}
            {...inputProps}
        />
    )
}

export default Button