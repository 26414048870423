import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useState } from 'react';
import {
    Container,
    BackgroundMask,
    TextWrapper,
    Title,
    Subtitle,
    Description
} from './Card.styled'

const Card = ({ title, subtitle, description, img }) => {

    return (
        <Container img={img}>
            <BackgroundMask />
            <TextWrapper>
                <Title>
                    {title}
                </Title>
                <Subtitle>
                    {subtitle}
                </Subtitle>
                <Description>
                    {description}
                </Description>
            </TextWrapper>
        </Container>
    )
}

export default Card