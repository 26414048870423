import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Container
} from './Home.styled'

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/littletree');
    }, [])

    return (
        <Container>

        </Container>
    )
}

export default Home