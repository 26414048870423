import styled from 'styled-components'

export const StyledButton = styled.button`
    width: 180px;
    height: 40px;
    font-size: 18px;

    background-color: ${(props) => props.bgColor ? props.bgColor : props.theme.color.primary};
    color: ${(props) => props.color ? props.color : props.theme.color.onPrimary};

    border: ${(props) => props.borderColor ? `1px solid ${props.borderColor}` : 'none'};
    
    cursor: pointer;

    &:hover:enabled {
        transform: scale(0.98);
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
`