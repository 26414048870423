import styled from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';

export const Container = styled.div`
    width: 100%;
    height: 85vh;
`

export const Wrapper = styled.div`
`

export const ProductConfirmation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    background-color: ${({ theme }) => theme.color.secondary};
    padding: 20px 40px;
`

export const Thankyou = styled.div` 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

export const ThankyouIcon = styled(FaCheckCircle)`
    color: green;
    margin-right: 5px;
`

export const ThankyouMsg = styled.h4`
    margin: 0px;
    @media(max-width: ${({ theme }) => theme.screen.sizeS}) {
        font-size: 0.8em;
    }
`

export const ProductDetail = styled.div`
    margin-bottom: 20px;
`

export const Title = styled.div`

    & h2 {
        margin-bottom: 5px;
    }

    @media(max-width: ${({ theme }) => theme.screen.sizeS}) {
        & h2 {
            font-size: 1.2em;
        }
    }
`

export const DateAndLocation = styled.div`
    color: ${({ theme }) => theme.color.font};
    opacity: 0.6;

    @media(max-width: ${({ theme }) => theme.screen.sizeS}) {
        font-size: 0.8em;
    }
`

export const Tickets = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 40px 0px;    
`

export const SentMsg = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    & > span {
        margin: 0px;
        padding: 0px;

        font-size: 0.8em;
    }
`

export const TicketContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 20px 0px;
`

export const NotFound = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 60vh;
`