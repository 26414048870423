import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useState } from 'react';
import {
    Section,
    Cell,
    TextWrapper,
    ButtonWrapper
} from '../LittleTree.styled'
import {
    ContentWrapper,
    SponsorWrapper,
    SponsorContainer,
    SponsorBenefits
} from './SupportUs.styled'
import Card from '../Card/Card';
import { Button } from '../../../component';

const SupportUs = () => {
    const handleSponsor = () => {
        window.open('https://donate.stripe.com/5kA9BWdCD8wf5hebIJ');
    }

    return (
        <>
            <Section>
                <Cell bgColor={'#695533'}>
                    <ContentWrapper>
                        <TextWrapper textAlign={'center'} fontColor={'#ffffff'}>
                            <h1>Support Us</h1>
                            <p>
                                새로이 시작하는 뮤지션들과의 공연인 만큼 그들의 꿈을 응원하는 많은 도움의 손길을 필요로 합니다.
                                후원해 주시는 따듯한 마음들로 인해 한인 커뮤니티의 공연 문화와 새내기 뮤지션들의 미래에 큰 힘이 될 것입니다.
                                물질적인 부분 외에도 공연장소 제공 및 공연 홍보 등 다양한 분야에서 후원이 가능합니다.
                            </p>
                        </TextWrapper>
                        <ButtonWrapper>
                            <Button
                                bgColor={'white'}
                                color={'#695533'}
                                borderColor={'#695533'}
                                onClick={handleSponsor}
                            >
                                <b>{"Sponsor"}</b>
                            </Button>
                        </ButtonWrapper>
                    </ContentWrapper>
                </Cell>
            </Section>
            <Section>
                <Cell bgColor={'#f9f1e4'}>
                    <ContentWrapper>
                        <TextWrapper textAlign={'center'}>
                            <h1>Benefits</h1>
                        </TextWrapper>
                        <SponsorContainer height='auto'>
                            <SponsorWrapper>
                                <Card
                                    title="SEED 씨앗심기"
                                    subtitle="Sponsorship"
                                    description="little tree 공연을 위해 1회 후원하기"
                                    img={process.env.PUBLIC_URL + '/images/littleTree/seed.jpg'}
                                />
                                <TextWrapper textAlign={'center'}>
                                    <h2>SEED Sponsorship</h2>
                                </TextWrapper>
                                <SponsorBenefits>
                                    <li>Logo on concert posters</li>
                                    <li>Free Admission to concert</li>
                                    <li>Announcement at concert + support</li>
                                    <li>Free concert merchandise/souvenirs</li>
                                    <li>SNS Shoutout</li>
                                </SponsorBenefits>
                            </SponsorWrapper>
                            <SponsorWrapper>
                                <Card
                                    title="SPROUT 새싹"
                                    subtitle="Partnership"
                                    description="little tree 공연을 위해 4회 이상 후원하기"
                                    img={process.env.PUBLIC_URL + '/images/littleTree/sprout.jpg'}
                                />
                                <TextWrapper textAlign={'center'}>
                                    <h2>SPROUT Partnership</h2>
                                </TextWrapper>
                                <SponsorBenefits>
                                    <li>ALL benefits from SEED Sponsorship</li>
                                    <li>+ Logo on home page website</li>
                                    <li>+ IG Highlight Shoutout</li>
                                </SponsorBenefits>
                            </SponsorWrapper>
                        </SponsorContainer>
                    </ContentWrapper>
                </Cell>
            </Section>
        </>
    )
}

export default SupportUs