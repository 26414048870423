import styled from 'styled-components';

export const ButtonContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: relative;
`

export const SpinnerContainer = styled.div`
    position: absolute;
    right: 0;
`