import { useNavigate } from 'react-router-dom'
import {
    Section,
    Cell,
    ImageCell,
    Image,
    TextWrapper,
    ButtonWrapper,
    CardWrapper,
} from '../LittleTree.styled'
import Card from '../Card/Card';
import { Button } from '../../../component';

const LittleTreeHome = () => {
    const navigate = useNavigate();

    const handleSponsor = () => {
        navigate('/littletree/support');
    }

    const handleApplication = () => {
        window.open('https://my.forms.app/littletree/application');
    }

    const handleRsvp = () => {
        window.open('https://my.forms.app/littletree/rsvp-form');
    }

    return (
        <>
            <Section>
                <Cell bgColor={'#f9f1e4'}>
                    <TextWrapper textAlign={'center'} width={'50%'}>
                        <h1>About Us</h1>
                        <p>"little tree" 는 새싹같이 시작하는 뮤지션들을 응원하며,
                            그들이 함께 할 수 있는 무대를 제공하기 위한 공연 플랫폼입니다.
                            한인 커뮤니티의 공연 문화와 숨어있는 많은 아티스트들의
                            다양한 재능과 꿈을 펼칠 수 있는 자리를 제공합니다.</p>
                    </TextWrapper>
                </Cell>
            </Section>
            <Section>
                <ImageCell flex={2}>
                    <Image>
                        <img src={process.env.PUBLIC_URL + `/images/littleTree/our-goal.jpg`} alt='' />
                    </Image>
                </ImageCell>
                <Cell flex={1}>
                    <TextWrapper>
                        <h1>Our Goal</h1>
                        <p>
                            "little tree" 는 봄, 가을 2번의 시즌제로 공연을 진행 할 예정이며,
                            음악뿐만 아니라 아트와 영상 등 다양한 분야의 아티스트들과의 콜라보로
                            폭 넓은 페스티벌로의 성장을 목표로 합니다.
                        </p>
                    </TextWrapper>
                </Cell>
            </Section>
            <Section>
                <Cell flex={2} bgColor={'#695533'}>
                    <TextWrapper fontColor={'#ffffff'}>
                        <h1>Support Us</h1>
                        <p>
                            새로이 시작하는 뮤지션들과의 공연인 만큼 그들의 꿈을 응원하는 많은 도움의 손길을 필요로 합니다.
                            후원해 주시는 따듯한 마음들로 인해 한인 커뮤니티의 공연 문화와 새내기 뮤지션들의 미래에 큰 힘이 될 것입니다.
                            물질적인 부분 외에도 공연장소 제공 및 공연 홍보 등 다양한 분야에서 후원이 가능합니다.
                        </p>
                    </TextWrapper>
                    <ButtonWrapper>
                        <Button
                            bgColor={'white'}
                            color={'#695533'}
                            borderColor={'#695533'}
                            onClick={handleSponsor}
                        >
                            <b>{"Find Out More >"}</b>
                        </Button>
                    </ButtonWrapper>
                </Cell>
                <Cell flex={3} bgColor={'#f9f1e4'}>
                    <CardWrapper>
                        <Card
                            title="SEED 씨앗심기"
                            subtitle="Sponsorship"
                            description="little tree 공연을 위해 1회 후원하기"
                            img={process.env.PUBLIC_URL + '/images/littleTree/seed.jpg'}
                        />
                        <Card
                            title="SPROUT 새싹"
                            subtitle="Partnership"
                            description="little tree 공연을 위해 4회 이상 후원하기"
                            img={process.env.PUBLIC_URL + '/images/littleTree/sprout.jpg'}
                        />
                    </CardWrapper>
                </Cell>
            </Section>
            <Section>
                <Cell flex={2} >
                    <TextWrapper textAlign={'center'}>
                        <h1>Events</h1>
                        <p>
                            [Oct 15th Sat] Little Tree / Craft Burger @ Old Milton
                        </p>
                        <ButtonWrapper>
                            <Button
                                bgColor={'#8a9e79'}
                                color={'#ffffff'}
                                borderColor={'#ffffff'}
                                onClick={handleRsvp}
                            >
                                {"RSVP"}
                            </Button>
                        </ButtonWrapper>
                    </TextWrapper>
                </Cell>
                <Cell flex={1} bgColor={'#8a9e79'}>
                    <TextWrapper textAlign={'center'} fontColor={'#ffffff'} >
                        <h1>Concert Application</h1>
                        <p>
                            Do you want to be the next performer?
                        </p>
                        <ButtonWrapper>
                            <Button
                                bgColor={'#695533'}
                                color={'#ffffff'}
                                borderColor={'#ffffff'}
                                onClick={handleApplication}
                            >
                                {"Form"}
                            </Button>
                        </ButtonWrapper>
                    </TextWrapper>
                </Cell>
            </Section>
        </>
    )
}

export default LittleTreeHome