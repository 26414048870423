import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
`

export const InfoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: ${({ direction }) => direction ? direction : 'row'};
    flex: ${({ flex }) => flex ? flex : 1};

    width: 100%;

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        flex: 1;
        flex-direction: column;
    }
`

export const Info = styled.div`
    display: flex;
    justify-content: center;
    align-items: ${({ align }) => align ? align : 'flex-start'};
    text-align: left;
    flex-direction: ${({ direction }) => direction ? direction : 'column'};
    flex: ${({ flex }) => flex ? flex : 1};

    background-color: ${({ bgColor }) => bgColor ? bgColor : '#ffffff'};
    color: ${({ fontColor }) => fontColor ? fontColor : '#695533'};
    & * {
        color: ${({ fontColor }) => fontColor ? fontColor : '#695533'};
    }
    border: 1px solid black;
    border-color: ${({ bgColor, borderColor }) => borderColor ? borderColor : bgColor ? bgColor : '#695533'};

    margin: 5px;
    padding: 15px 25px;
`

export const Logo = styled.div`
    width: 100%;
    /* height: 100%; */
    max-width: 400px;
    /* max-height: 70%; */
    padding: 20px;

    & img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    /*@media(max-width: ${({ theme }) => theme.screen.sizeL}) {
        max-width: 80%;
        max-height: 90%;
    } */
`