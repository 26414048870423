import styled from 'styled-components';
import { HiOutlineMusicNote } from 'react-icons/hi';

export const Container = styled.div`
    /* background-color: white; */
    position: relative;
    width: 100%;
    height: 100%;

    border: 1px solid ${({ theme }) => theme.color.border}55;
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    min-width: 650px;
    max-width: 700px;
    /* min-height: 250px; */

    position: relative;
    overflow: hidden;

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        flex-direction: column;
        min-width: 450px;
        min-height: 250px;
    }

    @media(max-width: ${({ theme }) => theme.screen.sizeS}) {
        flex-direction: column;
        min-width: 200px;
        min-height: 200px;
    }
`

export const ImgFlex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    flex: 4;

    border-right: 1px dashed ${({ borderColor }) => borderColor};

    position: relative;

    & img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        border: none;
        border-bottom: 1px dashed ${({ borderColor }) => borderColor};
    }
`

export const Name = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;

    color: ${({ theme }) => theme.color.primary};
    text-transform: uppercase;
`

export const ContentFlex = styled.div`    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex: 1;

    height: 100%;
    width: 100%;
`

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 0px 20px;

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-height: 150px;
    }
`

export const QRWrapper = styled.div`
    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        max-height: 120px;
        max-width: 120px;
    }
`

export const DetailsWrapper = styled.div`
    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        margin-left: 20px;
    }
`

export const Details = styled.div`
    margin-top: 5px;
    & h4 {
        margin: 0px;
        padding: 0px;
        color: ${({ colors }) => colors[0]};

        font-size: 1em;
    }

    & p {
        margin: 0px;
        padding: 0px;
        color: ${({ colors }) => colors[1]};
        
        font-size: 0.8em;
    }
`

export const Title = styled.h3`
    margin: 0px;
    margin-bottom: 5px;
`

export const Date = styled.p`
    margin: 0px;
    margin-bottom: 5px;
    font-size: 15px;
`

export const Location = styled.p`
    margin: 0px;
    font-size: 12px;

    @media(max-width: ${({ theme }) => theme.screen.sizeM}) {
        text-align: center;
        padding: 0px 10px;
    }   
`

export const MusicIcon = styled(HiOutlineMusicNote)`
    position: absolute;

    font-size: 8.5em;
    bottom: -10px;
    right: -30px;

    z-index: -1;

    opacity: 0.2;
`